import { useState, useEffect, ReactElement, lazy, Suspense } from 'react';
import useStyles from './styles';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
/* Utils - Services - Constants */
import { updateUserLanguage } from 'api';
import {
  clearUserSessionFromStorage,
  deferImport,
  isImpersonated,
} from 'utils/helper';
import i18next, { supportedLanguages } from 'utils/i18n';
import {
  isOwner,
  isQRCodeLoginUser,
  checkHasParentCustomer,
  validateFeatureNotAvailable,
  checkUserPermissions,
  isAdminLight,
} from 'utils/userRoleUtils';
import {
  revertImpersonate,
  setLanguageCode,
  setSystemColumns,
} from 'services/user/slice';
import {
  languageCodeSelector,
  userDataSelector,
} from 'services/user/selectors';
import { sectionHeaderTranslationSelector } from 'services/location-management/selectors';
import { fetchSectionSDSHeaderTranslation } from 'services/location-management/thunks';
import { logout } from 'store/pageAction/pageAction';
import { logout as logoutThunk } from 'services/user/slice';
import { AppDispatch, LANGUAGES } from 'constant';
import { PERMISSION, USER_PERMISSION } from 'enums/permissions.enums';
import UserInterface, { UpdateUserResponse } from 'interfaces/UserInterface';
import { LanguageItem } from 'interfaces/common';
/* Components */
import { keyframes } from '@emotion/react';
import { styled } from '@mui/material/styles';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemProps,
  MenuItem,
  Select,
  Typography,
  ClassNameMap,
  SelectChangeEvent,
} from '@mui/material';
import {
  ExitToAppRounded,
  Settings,
  Language,
  CorporateFare,
  ManageAccounts,
  Groups,
  PostAdd,
  RocketLaunch,
  PersonOutlineOutlined as PersonOutlineOutlinedIcon,
  PersonAddAlt,
  Password,
} from '@mui/icons-material';
import SubscriptionIcon from 'assets/icons/Subscription';
import Avatar from 'components/commons/avatar';
import LoadingModal from 'components/loader/LoadingModal';

/* Lazy load component */
const InviteUserPopup = lazy((): Promise<any> => {
  return deferImport(
    import('components/popup/invite-user-popup/InviteUserPopup')
  );
});
/* End */

const blinkAnimation = keyframes`
  0% { background-color: #ffffff; },
  50% { background-color: #ffc93c; },
  100% { background-color: #ffffff; }
`;

const BlinkedListItem = styled((props: ListItemProps) => (
  <ListItem {...props} />
))(() => ({
  animation: `${blinkAnimation} 1s 2 ease`,
}));

interface ProfileMenuListItem {
  id: string;
  label: string | ReactElement;
  onClick?: () => void;
  blinked?: boolean;
  icon?: ReactElement;
  show?: boolean;
  listItemProps?: ListItemProps;
  permission?: USER_PERMISSION;
}

interface AppsPopoverContentProps {
  user: UserInterface | null;
  onClose: () => void;
  onImpersonate: () => void;
}

const AppsPopoverContent = ({
  user,
  onClose,
  onImpersonate,
}: AppsPopoverContentProps) => {
  const { t } = useTranslation([
    'common',
    'apps_popover',
    'languages',
    'import_product',
    'side_bar',
    'account_settings_page',
  ]);
  const history = useHistory();
  const classes: ClassNameMap = useStyles();
  const dispatch: AppDispatch = useDispatch();
  /* Selectors */
  const languageCode = useSelector(languageCodeSelector);
  const userInfo = useSelector(userDataSelector);
  const sectionSDSHeaders = useSelector(sectionHeaderTranslationSelector);
  /* State */
  const [translatedNames, setTranslatedNames] = useState<Array<LanguageItem>>(
    []
  );
  const [openInvitePopup, setOpenInvitePopup] = useState<boolean>(false);
  /* Constant */
  const isQRLogin = isQRCodeLoginUser(userInfo);

  const handleLanguageChange = (value: string): void => {
    if (!user) return;
    const updateUserLanguageRequest = updateUserLanguage(user.id, value);
    updateUserLanguageRequest.then((response: UpdateUserResponse) => {
      if (response.status === 200) {
        dispatch(setLanguageCode(response.data.language_code));
        dispatch(
          setSystemColumns(
            response.data.inventory_manager_settings
              ?.additional_data_columns_v2 ?? []
          )
        );
        if (sectionSDSHeaders) dispatch(fetchSectionSDSHeaderTranslation());
      }
    });

    let setLang = supportedLanguages.includes(value) ? value : 'en';
    if (i18next.language !== setLang) i18next.changeLanguage(setLang);
  };

  const handleLogout = (): void => {
    clearUserSessionFromStorage();
    dispatch(logout());
    dispatch(logoutThunk());
    history.push(`/login/`);
  };

  const renderProfileInfo = (): ReactElement => {
    return (
      <ListItem
        onClick={() => {
          onClose();
          !isQRLogin && history.push('/account/');
        }}
        button={true}
        className={classes.listItem}
        style={{ borderBottom: '1px solid #cccccc8f' }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Avatar user={user} style={{ marginRight: 16 }} />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography style={{ fontSize: 14, lineHeight: '24px' }}>
              {`${user?.first_name} ${user?.last_name} `}
            </Typography>
            <Typography style={{ fontSize: 12 }}>
              {`${user?.customer?.name} `}
              <span className={classes.planChip}>
                {user?.customer?.inventory_subscription_plan?.plan.label}
              </span>
            </Typography>
          </div>
        </div>
      </ListItem>
    );
  };

  const renderProfileMenuListItem = (
    children: ReactElement,
    item: ProfileMenuListItem
  ): ReactElement => {
    if (item.blinked) {
      return (
        <BlinkedListItem
          key={item.id}
          className={classes.listItem}
          onClick={() => {
            if (item.permission && validateFeatureNotAvailable(item.permission))
              return;

            item.onClick !== undefined && item.onClick();
          }}
          {...item.listItemProps}
        >
          {children}
        </BlinkedListItem>
      );
    }

    return (
      <ListItem
        key={item.id}
        className={classes.listItem}
        onClick={() => {
          if (item.permission && validateFeatureNotAvailable(item.permission))
            return;

          item.onClick !== undefined && item.onClick();
        }}
        {...item.listItemProps}
      >
        {children}
      </ListItem>
    );
  };

  const renderLanguageSelect = (): ReactElement => {
    return (
      <div style={{ flex: 1 }}>
        <Select
          id="language"
          labelId="language-input-label"
          defaultValue={languageCode ?? ''}
          label={`${t('common:language')}`}
          className={classes.selectInput}
          onChange={(event: SelectChangeEvent<string>) =>
            handleLanguageChange(event.target.value)
          }
          MenuProps={{
            classes: { paper: classes.selectMenu },
            PaperProps: {
              style: {
                maxHeight: '450px',
              },
            },
          }}
        >
          {translatedNames.map((el: LanguageItem, i: number) => (
            <MenuItem key={i} value={el.code}>
              {el.name}
            </MenuItem>
          ))}
        </Select>
      </div>
    );
  };

  useEffect(() => {
    const sortedLanguages = LANGUAGES
      .filter(el => supportedLanguages.indexOf(el.code) !== -1)
      .map(el => {
        return { ...el, name: t(`languages:${el.code}`) };
      });

    setTranslatedNames(sortedLanguages);
  }, [languageCode]);

  const profileMenuList: Array<ProfileMenuListItem> = [
    {
      id: 'set_password',
      label: t('account_settings_page:set_password'),
      onClick: () => {
        onClose();
        history.push('/account/?action=change-password');
      },
      blinked: true,
      icon: <Password />,
      show: !isQRLogin && !!userInfo?.need_to_set_password,
    },
    {
      id: 'manage_account',
      label: t('apps_popover:manage_account'),
      onClick: () => {
        onClose();
        history.push('/account/');
      },
      icon: <ManageAccounts />,
      show: !isQRLogin,
    },
    {
      id: 'organization_profile',
      label: t('sidebar:organization_profile'),
      onClick: () => {
        onClose();
        history.push('/administration/organization/');
      },
      icon: <CorporateFare />,
      permission: PERMISSION.ACCESS_TO_ORGANIZATION_PAGE,
      show: !isQRLogin && isOwner(userInfo),
    },
    {
      id: 'user_management',
      label: t('sidebar:user_management'),
      onClick: () => {
        onClose();
        history.push('/administration/user-management/');
      },
      icon: <Groups />,
      permission: PERMISSION.ACCESS_TO_USER_MANAGEMENT_PAGE,
      show:
        /* Hide when user is login via QR */
        !isQRLogin,
    },
    {
      id: 'subscription',
      label: t('sidebar:subscription'),
      onClick: () => {
        onClose();
        history.push('/administration/subscription/');
      },
      icon: <SubscriptionIcon />,
      permission: PERMISSION.ACCESS_TO_SUBSCRIPTION_PAGE,
      show: !isQRLogin && checkHasParentCustomer(userInfo),
    },
    {
      id: 'go_live_checklist',
      label: t('apps_popover:go_live_checklist'),
      onClick: () => {
        onClose();
        history.push(`/go-live-checklist/`);
      },
      icon: <RocketLaunch />,
      permission: PERMISSION.ACCESS_TO_GO_LIVE_CHECKLIST_PAGE,
      show: !isQRLogin,
    },
    {
      id: 'impersonate',
      label: t('apps_popover:impersonate'),
      onClick: () => {
        onClose();
        onImpersonate();
      },
      icon: <PersonOutlineOutlinedIcon />,
      show: !isQRLogin && userInfo?.allow_impersonate && !isImpersonated(),
    },
    {
      id: 'cancel_impersonate',
      label: t('apps_popover:cancel_impersonate'),
      onClick: () => {
        onClose();
        dispatch(revertImpersonate());
      },
      icon: <PersonOutlineOutlinedIcon />,
      show: !isQRLogin && isImpersonated(),
    },
    {
      id: 'language',
      label: renderLanguageSelect(),
      icon: <Language />,
      show: !isQRLogin,
    },
    {
      id: 'invite_members',
      label: t('sidebar:invite_members'),
      onClick: () => {
        setOpenInvitePopup(true);
      },
      icon: <PersonAddAlt />,
      permission: PERMISSION.CREATE_USERS,
      show: !isQRLogin,
    },
    {
      id: 'import_product_lists',
      label: t('import_product:import_product_lists'),
      onClick: () => {
        onClose();
        history.push('/administration/import-product-list/');
      },
      icon: <PostAdd />,
      permission: PERMISSION.IMPORT_PRODUCT_LIST,
      show: !isQRLogin && checkUserPermissions(PERMISSION.IMPORT_PRODUCT_LIST, false),
    },
    {
      id: 'settings',
      label: t('sidebar:setting_function'),
      onClick: () => {
        onClose();
        history.push('/administration/setting-function/');
      },
      icon: <Settings />,
      permission: PERMISSION.ACCESS_TO_SETTING_FUNCTION_PAGE,
      show: !isQRLogin,
    },
    {
      id: 'logout',
      label: t('apps_popover:logout'),
      onClick: () => handleLogout(),
      icon: <ExitToAppRounded />,
      show: true,
      listItemProps: {
        style: {
          borderTop: '1px solid #cccccc8f',
        },
      },
    },
  ];

  return (
    <div className={classes.popover}>
      <List>
        {renderProfileInfo()}

        {profileMenuList.map((item: ProfileMenuListItem) => {
          if (!item.show) return null;
          if (item.permission && !checkUserPermissions(item.permission, true))
            return null;

          return renderProfileMenuListItem(
            <>
              <ListItemIcon>{item.icon}</ListItemIcon>
              {typeof item.label === 'string' ||
              item.label instanceof String ? (
                <ListItemText primary={item.label} />
              ) : (
                item.label
              )}
            </>,
            item
          );
        })}
      </List>

      <Suspense fallback={<LoadingModal />}>
        {openInvitePopup && (
          <InviteUserPopup onClose={() => setOpenInvitePopup(false)} />
        )}
      </Suspense>
    </div>
  );
};

export default AppsPopoverContent;
