import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  },
  page: {
    position: 'relative',
    height: '100%',
    paddingBottom: 0,
    overflow: 'hidden',
    display: 'flex',
    marginTop: '65px',
    flex: 1,
    transition: 'all 0.3s ease 0s',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      width: '100%',
      flexDirection: 'column',
      marginTop: 0,
    },
  },
  sidebarOpen: {
    position: 'relative',
    background: 'rgba(0,0,0,0.5)',
  },
  sidebarOpenLocationSubMenu: {
    marginLeft: '300px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  contentWrapper: {
    width: '100%',
    padding: '20px 20px',
    paddingBottom: '10px',
    boxSizing: 'border-box',
    backgroundColor: '#F2F5F7',
    minHeight: 'calc(100vh - 100px)',
    [theme.breakpoints.down('sm')]: {
      padding: '10px',
    },
  },
  contentWrapperWithDemoQRAccount: {
    minHeight: 'calc(100vh - 105px)',
  },
  collapseSidebarBtn: {
    position: 'fixed',
    zIndex: 99,
    transform: 'translateX(-50%)',
    bottom: 100,
    width: 30,
    height: 30,
    boxShadow: '0 0 10px rgb(0 0 0 / 50%)',
    backgroundColor: '#fff',
    borderRadius: '50%',
  },
}));
export default useStyles;
