import React, { memo } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogTitleProps,
  DialogContentProps,
  DialogProps,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import useStyles from './styles';

type DesktopDialogProps = Partial<DialogProps> & {
  title?: string | React.ReactElement;
  titleElement?: React.ReactElement;
  onClose?: () => void;
  onCloseBtnPressed?: () => void;
  DialogContentProps?: DialogContentProps;
  DialogTitleProps?: DialogTitleProps;
  saving?: boolean;
  actions?: React.ReactNode;
  hideCloseIcon?: boolean;
};

export default memo<DesktopDialogProps>(
  ({
    title,
    titleElement,
    onClose,
    onCloseBtnPressed,
    children,
    DialogContentProps,
    DialogTitleProps,
    actions,
    hideCloseIcon,
    classes = {},
    ...props
  }) => {
    const handleClose = (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      onClose !== undefined && onClose();
    };

    const handleCloseBtnPressed = (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      onCloseBtnPressed !== undefined && onCloseBtnPressed();
      onClose !== undefined && onClose();
    };

    const customClasses = useStyles();

    return (
      <Dialog
        open
        onClose={handleClose}
        classes={{
          ...classes,
          paperWidthLg: customClasses.paperWidthLg,
          paperWidthMd: customClasses.paperWidthMd,
          paperWidthSm: customClasses.paperWidthSm,
        }}
        {...props}
        disableEnforceFocus={true}
      >
        {title && (
          <DialogTitle
            className={customClasses.dialogTitle}
            {...DialogTitleProps}
          >
            {title}
            {!hideCloseIcon && (
              <IconButton
                size="small"
                aria-label="close"
                onClick={handleCloseBtnPressed}
              >
                <CloseIcon />
              </IconButton>
            )}
          </DialogTitle>
        )}
        {titleElement && (
          <DialogTitle {...DialogTitleProps}>
            {titleElement}
            {!hideCloseIcon && (
              <IconButton
                size="small"
                aria-label="close"
                onClick={handleCloseBtnPressed}
              >
                <CloseIcon />
              </IconButton>
            )}
          </DialogTitle>
        )}
        <DialogContent
          {...DialogContentProps}
          classes={
            DialogContentProps?.classes
              ? {
                  ...DialogContentProps.classes,
                  root: `${DialogContentProps.classes?.root ?? ''} ${
                    customClasses.dialogContentRoot
                  }`,
                }
              : {
                  root: customClasses.dialogContentRoot,
                }
          }
        >
          {children}
        </DialogContent>
        {actions && <DialogActions>{actions}</DialogActions>}
      </Dialog>
    );
  }
);
